.question-list{
    height: 80vh;
    overflow-y: scroll !important;
    width: 90%;
    margin: 0 auto;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.09);
    padding: 2px 0;
    position: relative;
}
.question-list::-webkit-scrollbar{
    background: rgba(0,0,0,0.09);
    width: 12px !important;
}
.question-list::-webkit-scrollbar-thumb{
    background-color: rgba(0,0,0,0.4);
    cursor: pointer !important;
    border-radius: 10px;
}.question-list::-webkit-scrollbar-thumb:hover{
    background-color: rgba(0,0,0,0.6);
    border-radius: 10px;
}