body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.actions {
}
.actions span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
}
.col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal-body {
  display: flex;
  flex-direction: column;
}
span {
  text-transform: capitalize;
}
.header {
  color: white !important;
  width: 100%;
}
.btn-container{
  display: flex;
  justify-content:center ;gap: 10px;

}.goback{
  position: fixed !important;
  right: 5vw !important;
  top: 6vh !important;
}
.page-not-found{
  text-align: center;
  height: 100%;
}