.search>*{
    width: 200px;
}
.user-table{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    align-items: flex-start;
    padding: 1em ;
}
.top-btns{
    flex-direction: row-reverse;
    display: flex;
    justify-content: space-between;
    flex: 1;
    width: 100%;
    align-items: center;
    margin-bottom: 5px;
}