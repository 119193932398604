.body-customized{
    background-color: #fff;
    padding: 2rem;
    border: 1px solid rgb(202, 201, 201);
}
.icheck-primary{
    margin-left: -4rem;
}
a{
    text-decoration: none;
    color: #fff;
}
a:hover{
    color: #fff;
}
.is-invalid-message-color{
  color: red;
}
.invalid-position{
  position: relative;
  right: 30%;
  border: 1px solid red;

}
.adjust-validation-err-margin{
  display: flex;
  justify-content: flex-start;
  position: relative;
}
