.app{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  overflow: hidden;
}
.home{
  display: flex;
  flex-direction: column;
  flex: 1;
}
.home>.top-nav{
  flex: .15;
}
.home>.content-wrapper,.loader{
  flex: .9;
  height: 100%;
  min-height: 90vh;
  max-height: 90vh;
}
.overlay{
background-color:rgba(0,0,0,0.7);
backdrop-filter: blur(10px);
z-index: -1;
width: 100%;
height: 100%;
position: absolute;
}
.loader{
  position: relative;
display: flex;
align-items: center;
text-align: center;
justify-content: center;
}
.custom-spinner {
font-size: 200px;
z-index: 1 !important;
color: white !important;
}
.main-footer {
  max-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
 }
.actions span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.actions span span {
  font-size: 14px;
}
.input-group 
{
  display: flex;
}
.input-group>input {
 flex: 1;   
 border: 1px solid rgba(0,0,0,0.5);
}
input:focus{
  box-shadow: 0px 1px 20px 1px rgba(0,0,0,0.09);
}
.ant-table-thead>.ant-table-cell{
  text-align: center !important;
}
.ant-table-tbody{
text-align:left !important;
}
.search>input{
  max-width: 300px;
}
.nav-link{
gap: 5px;
display: flex;
align-items: center;
}
.content-wrapper{
  max-height: 80%;
  min-height: 80%;
}
.content{
  padding: 1em;
}
thead tr th {
  text-align: center !important;
}
.list{
  text-transform: capitalize ;
}
.navbar-search-block{
  width: 90%;
}